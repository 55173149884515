import { AuthorDetail } from "../components/Paper";

const MAX_N_AUTHORS = 16;

export function shortenAuthorsAndHighlightedIndices(authors, indexes, maxNAuthors = MAX_N_AUTHORS) {
  /*
  * Shorten authors string to maxNAuthors authors and shortens the highlighted indices accordingly
  * Highlighted indices are used to highlight search terms. structure: [start1, end1, start2, end2, ...]
  * example indexes: [0, 10, 15, 28]
  * example authors: "author1, author2, author3, author4, author5, author6, author7"
  * resulting highlighting indicated by square brackets:  "[author1, a]uthor[2, author3, a]uthor4, author5, author6, author7"
  */
  // check in case api returns no authors
  if (!authors) {
    return ['', []];
  }
  let split_authors = authors.split(',')

  // Shorten authors string only if it contains more than maxNAuthors
  if (split_authors.length > maxNAuthors) {
    let first_N_authors = split_authors.slice(0, maxNAuthors - 1).join(',');
    let last_author = split_authors[split_authors.length - 1];

    // check if last author is highlighted
    let highlightLastAuthor = authors.length === indexes.at(-1);

    authors = first_N_authors + " ... " + last_author;
    // keep highlights for the authors before the ...
    indexes = indexes.filter(idx => idx < first_N_authors.length);

    if (highlightLastAuthor) {
      indexes.push(authors.length - last_author.length);
      indexes.push(authors.length);
    };

  }
  return [authors, indexes];
}

export function shortenAuthors(authors, maxNAuthors = MAX_N_AUTHORS) {
  /*
  * Shorten authors string to maxNAuthors authors
  * In the case of no indices, shortenAuthorsAndHighlightedIndices will skip the index related operations,
    so I think calling it with an empty index array will do fine
  */
  return shortenAuthorsAndHighlightedIndices(authors, [], maxNAuthors)[0];
}

function extractHighlightedParts(authors: string, indexes: number[]): string[] {
  const highlightedParts: string[] = [];

  // Iterate over the indexes in pairs of (start_idx, end_idx)
  for (let i = 0; i < indexes.length; i += 2) {
      const start_idx = indexes[i];
      const end_idx = indexes[i + 1];
      // Extract the substring between start_idx and end_idx
      const part = authors.slice(start_idx, end_idx);
      highlightedParts.push(part);
  }

  return highlightedParts;
}

function setHighlightAuthorDetails(authorDetails: AuthorDetail[], highlightedParts: string[]): AuthorDetail[] {
  return authorDetails.map((authorDetail) => {
      const shouldHighlight = highlightedParts.some((highlightedPart) => 
          authorDetail.author_name.includes(highlightedPart)
      );

      // Return a new object with the `highlighted` field set if necessary
      return {
          ...authorDetail,
          highlighted: shouldHighlight ? true : authorDetail.highlighted,
      };
  });
}

/*
 * set highlights for authors
*/
export function setHighlightsForAuthorDetails(authors: string, authorDetails: AuthorDetail[], indexes: number[]): AuthorDetail[] {
  const extractedHighlights = extractHighlightedParts(authors, indexes);
  const highlightedAuthorDetails = setHighlightAuthorDetails(authorDetails, extractedHighlights);
  return highlightedAuthorDetails;
}