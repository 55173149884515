import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import { Icon, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate } from "react-router-dom";
import { GetMaintenanceData, GetSessionData } from '../../services/AuthService';
import packageJson from '../../../package.json';
import HomepageLink from './HomepageLink';
import MenuIcon from '@mui/icons-material/Menu';
import { GetConferenceList } from '../../services/ConferenceService';
import { ConferenceListItem, ConferenceListResponse } from '../../pages/conferences/ConferencePage';
import MaintenanceBanner from "../Banner";
import theme from '../../theme';
import BreakpointUtils from '../../utils/BreakpointUtils';

const pages = [
  { text: 'Trending', to: '/trending', adminRequired: false },
  { text: 'Bookmarks', to: '/bookmarks', adminRequired: false },
  { text: 'Collections', to: '/collections', adminRequired: false },
  { text: 'Interactions', to: '/interactions', adminRequired: false },
  { text: 'Semantic Search', to: '/semantic-search', adminRequired: false },
];

const adminOnlyEndpoints = [
  '/settings/onboarding',
  '/settings/conference-planner',
  '/settings/users',
  '/settings/inactive-users',
  '/settings/statistics',
  '/settings/failed',
  '/settings/proceedings-uploader',
  '/settings/notify-users',
  '/settings/highlights-inference-settings',
  '/logging-view',
  '/settings/maintenance-control'
]

const settings = [
  { text: 'Settings', to: '/settings', adminRequired: false, icon: 'settings', disabled: false },
  { text: 'Bootstrapping', to: '/bootstrapping', adminRequired: true, icon: 'spa', disabled: false },
  { text: 'Iterative training', to: '/iterative-training', adminRequired: false, icon: 'school', disabled: false },
  { text: 'Feature words', to: '/feature-words', adminRequired: false, icon: 'favorite', disabled: false },
  { text: 'Changelog', to: '/changelog', adminRequired: false, icon: 'rss_feed', disabled: false },
  { text: 'Votes visualisation', to: '/tsne', adminRequired: false, icon: 'equalizer', disabled: true },
  { text: 'Arxiv availability', to: 'https://arxiv.org/help/availability', adminRequired: false, icon: 'access_time', disabled: false, target: '_blank' },
  { text: 'Our Blog', to: 'https://sites.google.com/view/avg-blog/scholar-inbox', adminRequired: false, icon: 'import_contacts', disabled: false, target: '_blank' },
  { text: 'Video Tutorials', to: '/tutorials', adminRequired: false, icon: 'smart_display', disabled: false },
  { text: 'About', to: '/about', adminRequired: false, icon: 'info', disabled: false },
  { text: 'Other tools', to: '/other-tools', adminRequired: false, icon: 'build', disabled: false },
  { text: 'Log out', to: '/logout', adminRequired: false, icon: 'meeting_room', disabled: false }
];

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export interface SearchResult {
  title: JSX.Element[];
  authors: string;
  url: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Header() {
  const onMobile = BreakpointUtils.lessThanMedium();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElConferences, setAnchorElConferences] = useState<null | HTMLElement>(null);
  const [conferencesExpanded, setConferencesExpanded] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [userSurname, setUserSurname] = useState<string>('');
  const conferencesOpen = Boolean(anchorElConferences);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [conferenceList, setConferenceList] = useState<ConferenceListItem[]>([]);
  const [showMaintenanceBanner, setShowMaintenanceBanner] = useState<boolean>(false);
  const [profilePicture, setProfilePicture] = useState<string>('');
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenConferencesMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElConferences(event.currentTarget);
    setConferencesExpanded(true);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseConferencesMenu = () => {
    setAnchorElConferences(null);
    setConferencesExpanded(false);
  };

  /*
  Consider this useEffect as an entry point to the app for logged in users
  Do essential checks:
    1. Check for version updates - delete cached files if required
    2. Check for onboarding status and redirect to the correct stage of registration if status isn't 'finished'
    3. Check for admin rights - allow display of admin options
  */
  useEffect(() => {
    // check version before doing anything
    let version = localStorage.getItem('version');
    console.log('latest: ' + packageJson.version);
    console.log('current: ' + version);
    if (version != packageJson.version) {
      if ('caches' in window) {
        window.caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
            console.log('deleting ' + name);
          })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload();
      }

      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }

    const maintenanceStatus = GetMaintenanceData();
    // block nav in case server is dead and we need to navigate to maintenance, and not navigate to '/' in GetSessionData handler
    let allowNav = true;
    maintenanceStatus.then(res => {
      // check if in maintenance mode
      if (!res.success || res.is_maintenance_mode) {
        allowNav = false;
        navigate({
          pathname: '/maintenance',
        });
      }
      else {
        setShowMaintenanceBanner(res.show_banner);
      }
    });

    const settingsData = GetSessionData();

    settingsData.then(res => {
      // check if logged in
      if (allowNav && (res === undefined || !res.is_logged_in)) {
        navigate({
          pathname: '/',
        });
      }
      // check onboarding status, go to the correct step specified
      switch (res.onboarding_status) {
        case 'registered':
          navigate({
            pathname: '/onboarding/registration',
            search: '?_s=1'
          });
          break;
        case 'bootstrapped':
          navigate({
            pathname: '/onboarding/registration',
            search: '?_s=2'
          });
          break;
        case 'trained':
          navigate({
            pathname: '/onboarding/registration',
            search: '?_s=3'
          });
          break;
        case null:
          navigate({
            pathname: '/logout',
          });
          break;
      }

      // redirect non admin user from restricted admin only pages
      if (!res.is_admin && adminOnlyEndpoints.includes(location.pathname)) {
        navigate({
          pathname: '/',
        });
      }

      // get conference list and update the header
      const conferenceList = GetConferenceList();
      conferenceList.then((res: ConferenceListResponse) => {
        if (res.success) {
          setConferenceList(res.conferences.reverse());
        }
      });

      setUserName(res.name.split(' ')[0]);
      setUserSurname(res.name.split(' ')[1]);
      setIsAdmin(res.is_admin);
      setProfilePicture(res.profile_picture);
    });
  }, []);

  let displayConferences = <Box>
    <IconButton
      id="conferences-menu-ctrl-btn"
      aria-controls={conferencesOpen ? 'conferences-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={conferencesOpen ? 'true' : undefined}
      onClick={handleOpenConferencesMenu}
      sx={{
        borderRadius: '10px',
        fontSize: '17px',
        ml: onMobile ? '7px' : '',
        color: onMobile ? 'black' : 'rgba(0, 0, 0, 0.54)'
      }}
      component='a'
    >
      Conferences
      <ExpandMore
        expand={conferencesExpanded}
        aria-expanded={conferencesExpanded}
        sx={{ height: '20px', width: '20px', ml: 1 }}
      >
        <ExpandMoreIcon />
      </ExpandMore>
    </IconButton>
    <Menu
      id="conferences-menu"
      anchorEl={anchorElConferences}
      open={conferencesOpen}
      onClose={handleCloseConferencesMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem key={'explore-conferences'} component={Link} to={'/conference-explorer'}>
        Conference Explorer
      </MenuItem>
      <Divider />
      {conferenceList.map((conference) => (
        <MenuItem key={conference.short_title} component={Link} to={'/conference/' + conference.conference_url}>
          {conference.short_title} Planner
        </MenuItem>
      ))}
    </Menu>
  </Box>

  return (
    <>
      <AppBar id="header-bar" position="sticky" sx={{
        borderRadius: '0px 0px 8px 8px!important',
        zIndex: (theme) => theme.zIndex.drawer + 2,
        maxWidth: theme.maxWidthContainer,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
        {showMaintenanceBanner ? <MaintenanceBanner /> : null}
        <Container maxWidth="xl">
          <Toolbar disableGutters variant='dense' >

            <HomepageLink />

            {/* MOBILE */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="secondary"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => {
                  // if admin permissions required and the user isn't an admin - skip the page
                  if (page.adminRequired && !isAdmin) return;
                  return (
                    <MenuItem key={page.text} component="a" href={page.to}>
                      <Typography textAlign="center">{page.text}</Typography>
                    </MenuItem>
                  )
                })}
                {displayConferences}

              </Menu>
            </Box>

            {/* CONFERENCE BANNER */}
            {/* {window.innerWidth < 900 && (
              <Box sx={{ mt: '5px', }}>
                <Link to={'/conference/neurips/2024'} >
                  <img src='/image/NeurIPS-logo.svg' style={{ height: '30px' }} />
                </Link>
              </Box>
            )} */}

            {/* DESKTOP LAYOUT */}
            <HomepageLink sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, width: '40px', height: '40px' }} />
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => {
                // if admin permissions required and the user isn't an admin - skip the page
                if (page.adminRequired && !isAdmin) return;
                return (
                  <IconButton
                    key={page.text}
                    onClick={handleCloseNavMenu}
                    sx={{
                      borderRadius: '10px',
                      fontSize: '17px',
                      // fontWeight: '500',
                    }}
                    component={Link}
                    to={page.to}
                  >
                    {page.text}
                  </IconButton>
                )
              })}
              {displayConferences}

              {/* CONFERENCE BANNER */}
              {/* show from 1080px to not overlap other buttons */}
              {/* {window.innerWidth > 1080 && (
                <Box sx={{ mt: '5px', }}>
                  <Link to={'/conference/neurips/2024'} >
                    <img src='/image/NeurIPS-logo.svg' style={{ height: '30px' }} />
                  </Link>
                </Box>
              )} */}

            </Box>

            <Box>
              <IconButton
                component={Link}
                to="/search"
                sx={{
                  // width: '7em',
                  height: '50px',
                  borderRadius: '10px',
                  fontSize: '17px',
                  // fontWeight: '500',
                  justifyContent: { xs: 'right', }
                }}
              >
                <SearchIcon sx={{ transform: 'scale(1.8)', }} />
                <Box sx={{ marginLeft: 1, display: { xs: 'none', md: 'flex' } }}>Search</Box>
              </IconButton>
            </Box>


            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar src={'/image/profile_picture/' + profilePicture} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                disableScrollLock
              >
                <MenuItem component={Link} to={'/settings'}>
                  <ListItemText>{userName} {userSurname}</ListItemText>
                </MenuItem>
                <Divider />
                {settings.map((setting) => (
                  <MenuItem disabled={setting.disabled} key={setting.text} component={Link} to={setting.to} target={setting.target ? setting.target : '_self'} color="secondary">
                    <Icon sx={{ mr: 1 }}>{setting.icon ? setting.icon : ''}</Icon>
                    <Typography textAlign="right">{setting.text}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;