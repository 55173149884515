import {
  AlertTitle, Box, Button, Collapse,
  Dialog, DialogContentText,
  DialogActions, Divider, IconButton, LinearProgress, Link, List, ListItem, ListItemText, styled, Tab, Tabs, TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddBaseAuthor, AddPapers, BaseAuthorsComplete, GetAddedAuthors, GetBaseAuthorsData, HideBaseAuthorsInstructions, PrunePapers, RemoveAuthor, RemovePaper, SearchBaseAuthors } from '../../../services/RegistrationService';
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import platform from 'platform';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BoxSection from '../../../components/box/BoxSection';
import BoxDefaultContent from '../../../components/box/BoxDefaultContent';
import { GetMaintenanceData } from "../../../services/AuthService";
import { useNavigate } from "react-router-dom";
import TripleDotLoadingProgress from '../../../components/tools/TripleDotLoadingProgress';
import ReplayIcon from '@mui/icons-material/Replay';
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const MINIMUM_NUM_PAPERS_REQUIRED_TO_CONTINUE = 10;

interface BaseAuthorsProps {
  // used to either increment stepper during registration or complete bootstrapping if user is logged in
  callback?: () => void;
}

export const PaperLink = styled(Link)(({ theme }) => ({
  color: 'green',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

interface SearchAuthorPaper {
  title: string;
  url: string;
  year: number;
  citations: number;
};

export interface SearchAuthorResult {
  name: string;
  author_id: number;
  url: string;
  hIndex: number;
  citations: number;
  papers: SearchAuthorPaper[];
};

interface AddedAuthor {
  name: string;
  id: string;
  papers: AddedAuthorPapers[];
};

interface AddedAuthorPapers {
  year: number;
  papers: AddedAuthorPaper[];
};

interface AddedAuthorPaper {
  id: string;
  title: string;
  url: string;
  enabled: boolean;
};

function ShowCoverageAlert(props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleContinue = () => {
    props.callback();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Insufficient Coverage"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Warning: Based on your selected papers we are unable to provide you with a good experience for your research area.
          You can still continue to the next step. Please share details about your field with us to improve our service.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Back
        </Button>
        <Button onClick={handleContinue} color="error" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function BaseAuthors(props: BaseAuthorsProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAddAuthor, setLoadingAddAuthor] = useState<boolean>(false);
  const [showInstructions, setShowInstructions] = useState<boolean>(true);
  const [sha_key, set_sha_key] = useState<string>('');
  const [searchPrompt, setSearchPrompt] = useState<string>('');
  const [finishingBootstrapping, setFinishingBootstrapping] = useState<boolean>(false);
  const [addedAuthors, setAddedAuthors] = useState<AddedAuthor[]>([]);
  const [searchResults, setSearchResults] = useState<SearchAuthorResult[]>([]);
  const [blockAddingMoreAuthors, setBlockAddingMoreAuthors] = useState<boolean>(false);
  const [showBookmarkInstructions, setShowBookmarkInstructions] = useState<boolean>(false);
  const [commandButton, setCommandButton] = useState<string>('Ctrl');
  const [tabDisplayedAuthor, setTabDisplayedAuthor] = useState<AddedAuthor>(null);
  const [tabsValue, setTabsValue] = useState<number>(0);
  const [displaySearchTip, setSearchTip] = useState<boolean>(false);
  const [showRetryButton, setShowRetryButton] = useState<boolean>(false);
  const [showCoverageAlert, setShowCoverageAlert] = useState(false);
  const [numberOfAddedPapers, setNumberOfAddedPapers] = useState<number>(0);

  const navigate = useNavigate();

  const maintenanceStatus = GetMaintenanceData();
  maintenanceStatus.then(res => {
    // check if in maintenance mode
    if (res.is_maintenance_mode) {
      navigate({
        pathname: '/maintenance',
      });
    }
  });

  const loadAddedAuthors = () => {
    const addedAuthors = GetAddedAuthors();
    addedAuthors.then(authors => {
      setLoadingAddAuthor(false);
      if (authors) {
        setAddedAuthors(authors);
        setTabDisplayedAuthor(authors[tabsValue])
      }
    });
  };

  const handleDataUpdate = (response) => {
    console.log(response)
    set_sha_key(response.sha_key);
    // guard vs bad response and show_login_instructions = null
    if (response.show_login_instructions !== null)
      setShowInstructions(response.show_login_instructions);
    setBlockAddingMoreAuthors(false);
    loadAddedAuthors();
  };

  // detect user bookmarking the page, restore previous url
  const keydownHandler = (e: KeyboardEvent) => {
    if (e.key === 'd' && (e.ctrlKey || e.metaKey)) {
      // if we have state to restore - restore old url
      setTimeout(() => {
        if (localStorage.getItem('savedUrl')) {
          history.pushState({}, null, localStorage.getItem('savedUrl'));
          localStorage.removeItem('savedUrl');
        }
      }, 100);
    }
  };

  useEffect(() => {
    const req = GetBaseAuthorsData();
    req.then(res => handleDataUpdate(res));

    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    }
  }, []);

  // recalc number of added papers each time authors change in any way
  useEffect(() => {
    const addedPapers = getPapersAddedCount();
    console.log('added: ' + addedPapers);
    setNumberOfAddedPapers(addedPapers);
  }, [addedAuthors]);

  const hideInstructionsClick = () => {
    const req = HideBaseAuthorsInstructions();
    req.then(() => {
      setShowInstructions(false);
    });
  };

  const handleFinishButtonClick = () => {
    const req = BaseAuthorsComplete();
    setFinishingBootstrapping(true);
    req.then(res => {

      if (res.success) {
        if (res.covered) {
          props.callback();
        }
        else {
          setShowCoverageAlert(true);
        }
      }
    })
  };

  const handleAlertClose = () => {
    setFinishingBootstrapping(false);
    setShowCoverageAlert(false);
  };

  const handleAddAuthorClick = (author_id: number) => {
    console.log('adding author id=' + author_id);
    setBlockAddingMoreAuthors(true);
    setLoadingAddAuthor(true);
    setSearchTip(false);
    setShowRetryButton(false);

    const req = AddBaseAuthor(author_id);
    req.then(res => {
      console.log(res);
      if (!res.success && res.reason === 'No budget available') {
        alert('Sorry, we are experiencing very high website load at the moment. Please try again in a few minutes.');
        setLoadingAddAuthor(false);
        setBlockAddingMoreAuthors(false);
        return;
      }

      if (!res.success && res.authors_added >= 5) {
        alert('You already added 5 authors. We recommend you move onto the "iterative training" step now.');
      }
      if (!res.success) {
        setShowRetryButton(true);
        setBlockAddingMoreAuthors(false);
        setLoadingAddAuthor(false);
        return;
      }
      setBlockAddingMoreAuthors(false);
      setSearchResults([]);
      loadAddedAuthors();
    });
  };

  const handleSearchSubmit = () => {
    event.preventDefault();
    // search authors
    setLoading(true);
    setSearchTip(false);
    const req = SearchBaseAuthors(searchPrompt);
    req.then(res => {
      if (!res.success && res.authors_added >= 5) {
        alert('You already added 5 authors. We recommend you move onto the "iterative training" step now.');
        setLoading(false);
        return
      }
      console.log(res);
      if (res) {
        setLoading(false);
        setSearchResults(res);
        if (res.length === 0) {
          setSearchTip(true);
        }
      }
    });
  };

  const handleRemoveAuthor = (author_id) => {
    const req = RemoveAuthor(author_id);
    req.then(() => {
      const filteredAuthors = addedAuthors.filter(author => author.id !== author_id);
      setAddedAuthors(filteredAuthors);
      setTabDisplayedAuthor(filteredAuthors[0]);
    });
  };

  function getPapersAddedCount() {
    // Total number of papers added across all authors
    let totalPapersAdded = 0;
    console.log(addedAuthors);
    addedAuthors.forEach(author => {
      author.papers.forEach(papers => {
        totalPapersAdded += papers.papers.filter(paper => paper.enabled).length;
      });
    });
    return totalPapersAdded;
  }

  function checkMoreThan300PapersAdded(res) {
    if (!res.success && res.over_threehundred_papers_added) {
      alert('You tried to add over 300 papers. We recommend you move onto the "iterative training" step now.');
      return true;
    }
    return false;
  }
  const handleAddPapersByYear = (author_id, year) => {
    // get papers ids for given author_id and year
    const paperIds = addedAuthors.find(author => author.id === author_id)
      .papers.find(papers => papers.year === year)
      .papers.map(paper => Number(paper.id));
    const req = AddPapers(paperIds);
    req.then(res => {
      console.log(res);
      if (checkMoreThan300PapersAdded(res)) {
        return;
      }

      // create a deep copy of added authors that we can modify
      const updatedList = JSON.parse(JSON.stringify(addedAuthors));

      updatedList.find(author => author.id === author_id)
        .papers.find(papers => papers.year === year)
        .papers.forEach((paper: AddedAuthorPaper) => paper.enabled = true);

      setAddedAuthors(updatedList);
      setTabDisplayedAuthor(updatedList[tabsValue]);
    });
  }

  const handleRemovePapersByYear = (author_id, year) => {
    // get papers ids for given author_id and year
    const paperIds = addedAuthors.find(author => author.id === author_id)
      .papers.find(papers => papers.year === year)
      .papers.map(paper => Number(paper.id));
    const req = PrunePapers(paperIds);
    req.then(() => {
      // create a deep copy of added authors that we can modify
      const updatedList = JSON.parse(JSON.stringify(addedAuthors));

      updatedList.find(author => author.id === author_id)
        .papers.find(papers => papers.year === year)
        .papers.forEach((paper: AddedAuthorPaper) => paper.enabled = false);

      setAddedAuthors(updatedList);
      setTabDisplayedAuthor(updatedList[tabsValue])
    });
  };

  const handleRemovePaper = (author_id, paper_year, paper_id) => {
    const req = RemovePaper(paper_id);
    req.then(() => {
      // create a deep copy of added authors that we can modify
      const updatedList = JSON.parse(JSON.stringify(addedAuthors));
      updatedList.find(author => author.id === author_id).papers
        .find(papers => papers.year === paper_year).papers
        .find(paper => paper.id === paper_id).enabled = false;
      setAddedAuthors(updatedList);
      setTabDisplayedAuthor(updatedList[tabsValue])
    });
  };

  const handleAddPaper = (author_id, paper_year, paper_id) => {
    const req = AddPapers([paper_id]);
    req.then(res => {
      console.log(res);
      if (checkMoreThan300PapersAdded(res)) {
        return;
      }
      // create a deep copy of added authors that we can modify
      const updatedList = JSON.parse(JSON.stringify(addedAuthors));
      updatedList.find(author => author.id === author_id).papers
        .find(papers => papers.year === paper_year).papers
        .find(paper => paper.id === paper_id).enabled = true;
      setAddedAuthors(updatedList);
      setTabDisplayedAuthor(updatedList[tabsValue])
    });
  };

  const bookmarkLink = () => {
    // save current url to restore after user bookmarks the page
    localStorage.setItem('savedUrl', window.location.pathname);

    // set url to be the login page for bookmarking (doesn't go there)
    history.pushState({}, null, '/login/' + sha_key);

    setShowBookmarkInstructions(true);
    if (platform.os.family && platform.os.family === 'OS X') {
      setCommandButton('Command (⌘)');
    }
  };

  const handleSearchInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPrompt(event.target.value);
  };

  return (

    <BoxDefaultContent>
      {showCoverageAlert && <ShowCoverageAlert callback={props.callback} onClose={handleAlertClose} />}
      {showInstructions && (
        <BoxSection sx={{
          // p: 1,
          // width: '70%',
          // m: 'auto',
          // mt: 2
        }}>
          <Collapse in={showInstructions}>
            <Alert
              severity="info"
              icon={false}
            >
              <AlertTitle>Login Information</AlertTitle>
              Here is your personal login link:
              <br />
              <b>https://www.scholar-inbox.com/login/{sha_key}
                <IconButton onClick={() => { navigator.clipboard.writeText('https://www.scholar-inbox.com/login/' + sha_key) }}>
                  <ContentCopyIcon />
                </IconButton>
              </b>
              <br />
              Please <b>bookmark it
                <IconButton onClick={() => bookmarkLink()}>
                  <BookmarkAddIcon />
                </IconButton>
              </b>
              and use it in the future to access the website.
              {showBookmarkInstructions && (<><br /><b><span>Press <strong>{commandButton} + D</strong> to add this page to your bookmarks.</span></b><br /></>)}
              <br />
            </Alert>
            <Button color='secondary' variant='contained' sx={{ float: 'right', backgroundColor: '#55b776', mt: 1 }} onClick={hideInstructionsClick}>Ok - I read this</Button>
          </Collapse>
        </BoxSection>
      )}

      <BoxSection sx={{
        // width: '60%',
        // m: 'auto',
        // alignItems: 'center'
      }}>
        <p>Add papers from authors that interest
          or relate to you. If you have published {'>'}10 papers, we recommend you to use your own publications to
          receive paper suggestions related to your past research. If you are a Master or PhD student you might
          want to add your supervisor’s publications.
        </p>
        <ul>
          {/* <li> The 10 most cited papers per author will be shown.</li>
          <li> This helps you to disambiguate authors with the same name.</li> */}
          <li> After adding an author, you should select some of their papers that match your interest.</li>
          <li> You can repeat these steps and add multiple authors (optional).</li>
          <li> We recommend to add papers from 1 to 3 base authors and up to 100 papers.</li>
        </ul>
        <p>
          Scholar Inbox will recommend papers that are similar to your selection during the iterative training phase.
        </p>
      </BoxSection>

      <BoxSection
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // width: '60%',
          // mx: 'auto',
        }}
      >
        <form
          onSubmit={handleSearchSubmit}
        // style={{
        //   width: '60%',
        //   margin: 'auto'
        // }}
        >
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} > */}
          {/* <IconButton onClick={handleSearchSubmit} sx={{ width: '50px', height: '50px', margin: '5px 0 5px 0' }} >
              <SearchIcon sx={{ transform: 'scale(1.8)', }} />
            </IconButton> */}
          <TextField autoFocus
            onInput={handleSearchInputs}
            sx={{
              fontSize: '50px',
              width: '100%',
              height: '100%',
              // margin: '0 11px 0 0',
            }}
            color="secondary"
            variant='outlined'
            placeholder='Search author by name ...'
          />
          {/* </Box> */}
        </form>
        <Button
          color='secondary'
          variant='contained'
          sx={{
            width: '100%',
            // mx: 'auto',
            textAlign: 'center',
            backgroundColor: '#55b776',
            mt: '2px'
          }}
          onClick={handleSearchSubmit}>
          Search
        </Button>
      </BoxSection>

      {loading && (
        <Alert
          severity='info'
          icon={false}
          sx={{
            // width: '30%',
            // m: 'auto',
            // mt: 2,
          }}>
          <AlertTitle>Loading...</AlertTitle>
          Searching Semantic Scholar for authors matching your request...
          <LinearProgress sx={{ width: '100%' }} color="info" />
        </Alert>
      )}

      {displaySearchTip && (
        <Alert
          severity='info'
          icon={false}
        >
          Semantic Scholar API can sometimes time out. Please try your search again.
          <br />
          You can also try being more specific with your query (i.e. provide the full name of the author including the first name).
        </Alert>
      )}

      {loadingAddAuthor && (
        <Alert
          severity="info"
          icon={false}
          sx={{
            // width: '30%',
            // m: 'auto',
            // mt: 2
          }}>
          <AlertTitle>Processing..</AlertTitle>
          Working with Semantic Scholar and adding this author...
          <LinearProgress sx={{ width: '100%' }} color="info" />
        </Alert>
      )}

      {finishingBootstrapping && (
        <Box sx={{ mt: 2, marginLeft: 'auto', marginRight: 'auto', width: '400px' }}>
          <p>Please wait ~6 seconds for the model to train</p>
          <Box sx={{ scale: '0.35' }}>
            <TripleDotLoadingProgress />
          </Box>
        </Box>
      )}

      {(props.hasOwnProperty('callback')) && (
        <>
          <Button
            color="secondary"
            variant='contained'
            sx={{
              width: '100px',
              marginTop: 2,
              backgroundColor: '#CC4E49',
              /* ml: 'calc(80% - 100px)' */
            }}
            title="Continue to the next step: Iterative Training"
            onClick={handleFinishButtonClick}
            disabled={addedAuthors.length < 1 || numberOfAddedPapers < MINIMUM_NUM_PAPERS_REQUIRED_TO_CONTINUE}
          >
            Continue
          </Button>
          {(addedAuthors.length >= 1 && numberOfAddedPapers >= MINIMUM_NUM_PAPERS_REQUIRED_TO_CONTINUE) && <Box sx={{ my: 2 }}></Box>}
          {(addedAuthors.length < 1 && numberOfAddedPapers < MINIMUM_NUM_PAPERS_REQUIRED_TO_CONTINUE) && (
            <Typography sx={{ color: 'red', marginBottom: 2, mt: 1 }}>
              Please select at least one author and at least ten papers to continue. (papers can be from multiple authors)
            </Typography>
          )}
          {(addedAuthors.length >= 1 && numberOfAddedPapers < MINIMUM_NUM_PAPERS_REQUIRED_TO_CONTINUE) && (
            <Typography sx={{ color: 'red', marginBottom: 2, mt: 1 }}>
              Please select at least ten papers to continue. (papers can be from multiple authors)
            </Typography>
          )}
        </>
        // {studyFieldEmptyError && (<FormHelperText id="component-error-text" sx={{ color: 'red' }}>Study field cannot be empty!</FormHelperText>)}
      )}

      {searchResults.length > 0 && (
        <BoxSection>
          <>
            {showRetryButton && (
              <Alert
                severity='info'
                icon={false}
              >
                We are currently experiencing issues with Semantic Scholar API. Please try adding author again by clicking the retry button.
              </Alert>
            )}
            {searchResults.map(author => {
              return (
                <>
                  {
                    showRetryButton && (
                      <Button color='secondary' size='small' variant='outlined'
                        sx={{ transform: 'scale(0.7)', mr: '2px', mb: '2px' }}
                        onClick={() => handleAddAuthorClick(author.author_id)}
                        disabled={blockAddingMoreAuthors}
                      >
                        <ReplayIcon />
                      </Button>
                    )
                  }
                  <Button color='secondary' size='small' variant='outlined'
                    sx={{ transform: 'scale(0.7)', mr: '2px', mb: '2px' }}
                    onClick={() => handleAddAuthorClick(author.author_id)}
                    disabled={blockAddingMoreAuthors}
                  >
                    Add
                  </Button>
                  <a href={author.url} target="_blank" style={{ color: 'black', textDecoration: 'none' }}>{author.name}</a> (h index: {author.hIndex} citations {author.citations})
                  <List dense={true}>
                    {author.papers.map(paper => (
                      <ListItem sx={{ mt: -2 }} key={paper.title}>
                        <ListItemText
                          primary={<><PaperLink target="_blank" href={paper.url}>{paper.title}</PaperLink> <span style={{ whiteSpace: 'nowrap' }}>(citations: {paper.citations})</span></>}
                        />
                      </ListItem>
                    ))}
                  </List>
                </>
              )
            })}
          </>
        </BoxSection>
      )}

      {(addedAuthors.length > 0 && searchResults.length == 0) && (
        <Box sx={{ m: 'auto' }}>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs scrollButtons="auto" variant="scrollable" allowScrollButtonsMobile textColor="secondary" indicatorColor="secondary" value={tabsValue} onChange={(event, newValue) => { setTabDisplayedAuthor(addedAuthors[newValue]); setTabsValue(newValue); }}>
              {addedAuthors.map((author, index) => (
                <Tab
                  label={author.name}
                  iconPosition="end"
                  style={{ 'minHeight': '50px' }}
                  icon={
                    <IconButton onClick={() => handleRemoveAuthor(author.id)}>
                      <HighlightOffIcon fontSize='small' />
                    </IconButton>}
                  value={index} />
              ))}
            </Tabs>
          </Box>

          <>
            <List dense={true}>
              {tabDisplayedAuthor.papers.map(papers => (
                <Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton onClick={() => {
                      handleAddPapersByYear(tabDisplayedAuthor.id, papers.year);
                    }}>
                      <AddCircleOutlineIcon fontSize='small' />
                    </IconButton>
                    <Typography variant='body2'><b>{papers.year}</b></Typography>
                    <IconButton onClick={() => {
                      handleRemovePapersByYear(tabDisplayedAuthor.id, papers.year);
                    }}>
                      <RemoveCircleOutlineIcon fontSize='small' />
                    </IconButton>
                  </Box>

                  {papers.papers.map(paper => (
                    <ListItem
                      sx={{
                        p: 0,
                        pr: '2px',
                        // maxWidth: '700px',
                        backgroundColor: paper.enabled ? 'rgba(5 195 92 / 14%)' : 'rgba(227, 3, 3, 0.14)'
                      }}
                      key={paper.title}
                    >
                      {paper.enabled ? (
                        <IconButton onClick={() => {
                          handleRemovePaper(tabDisplayedAuthor.id, papers.year, paper.id);
                        }}>
                          <RemoveCircleOutlineIcon fontSize='small' />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => {
                          handleAddPaper(tabDisplayedAuthor.id, papers.year, paper.id);
                        }}>
                          <AddCircleOutlineIcon fontSize='small' />
                        </IconButton>
                      )}
                      <ListItemText>
                        <PaperLink style={{ color: 'black' }} target="_blank" href={paper.url}>{paper.title}</PaperLink>
                      </ListItemText>
                    </ListItem>
                  ))}

                </Box>
              ))}

            </List>
            <Divider />
          </>

        </Box>
      )}

    </BoxDefaultContent>
  )
}