import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, IconButton, Input, InputLabel, Link, Snackbar, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
  CreateInvitationLinksPage,
  DeleteInvitationLinksPage,
  GenerateOnboardingLinks,
  GenerateOnboardingLinksAsTxt,
  GetOnboardingSettingsData,
  InviteUserByEmail,
  UpdateIterativeTrainingParameters,
  UpdateResigtrationAccess,
  UpdateWaitlistEnabled,
  UploadInvitesList
} from '../../services/SettingsService';
import { sortInviteLinksListByExpiryDateDescending } from '../../utils/listSortingUtils';
import BoxSettingsContent from '../../components/box/BoxSettingsContent';

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface GeneratedPage {
  name: string;
  total: number;
  used: number;
  expiryDate: Date;
  pageLink: string;
}

export default function OnboardingSettingsPage() {
  const [generateNumberOfLinks, setGenerateLinkNumber] = useState(5);
  const [generatedLinks, setGeneratedLinks] = useState<string[]>([]);
  const [inviteEmail, setInviteEmail] = useState('');
  const [file, setFile] = useState<File>();
  const uploadInputRef = useRef(null);
  const [emailInviteSnackbarOpen, setEmailInviteSnackbarOpen] = useState(false);
  const [iterativeTrainingSnackbarOpen, setIterativeTrainingSnackbarOpen] = useState(false);
  const [registrationAccessSnackbarOpen, setRegistrationAccessSnackbarOpen] = useState(false);
  const [numberOfLinksOnPage, setLinksOnPageNumber] = useState(10);
  const [onboardingName, setOnboardingName] = useState('');
  const [generatedPages, setGeneratedPages] = useState<GeneratedPage[]>([]);
  // iter training params
  const [boundaryBucketMin, setBoundaryBucketMin] = useState(0);
  const [boundaryBucketMax, setBoundaryBucketMax] = useState(0);
  const [boundaryBucketNumBins, setBoundaryBucketNumBins] = useState(0);
  const [registrationOpened, setRegistrationOpened] = useState<boolean>(true);
  const [waitlistEnabled, setWaitlistEnabled] = useState<boolean>(true);

  const handleEmailInviteSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setEmailInviteSnackbarOpen(false);
  };

  const handleRegistrationAccessSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setRegistrationAccessSnackbarOpen(false);
  };

  const handleIterativeTrainingSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIterativeTrainingSnackbarOpen(false);
  };

  const showGeneratedLinks = () => {
    const settingsData = GenerateOnboardingLinks(generateNumberOfLinks);
    settingsData.then(res => {
      setGeneratedLinks(res.links);
    });
  };

  const downloadAsTxt = () => {
    const settingsData = GenerateOnboardingLinksAsTxt(generateNumberOfLinks);
    settingsData.then(res => {
      saveAsFile(res, 'onboarding_links.txt');
    });
  };

  const saveAsFile = (text: string, filename: string) => {
    // Step 1: Create the blob object with the text you received
    const type = 'application/text'; // modify or get it from response
    const blob = new Blob([text], { type });

    // Step 2: Create Blob Object URL for that blob
    const url = URL.createObjectURL(blob);

    // Step 3: Trigger downloading the object using that URL
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click(); // triggering it manually
  };

  const sendInviteEmail = () => {
    const settingsData = InviteUserByEmail(inviteEmail);
    settingsData.then(res => {
      setEmailInviteSnackbarOpen(true);
    });
  };

  const updateIterativeTraining = () => {
    const updateRequest = UpdateIterativeTrainingParameters(boundaryBucketMin, boundaryBucketMax, boundaryBucketNumBins);
    updateRequest.then(res => {
      setIterativeTrainingSnackbarOpen(true);
    });
  };

  const deleteGeneratedPage = (page_key: string) => {
    const deleteRequest = DeleteInvitationLinksPage(page_key);
    deleteRequest.then(() => {
      const settingsData = GetOnboardingSettingsData();
      settingsData.then(res => {
        handleSettingsDataUpdate(res);
      });
    });
  };

  const generatePageLink = () => {
    const generateRequest = CreateInvitationLinksPage(onboardingName, numberOfLinksOnPage);
    generateRequest.then((res) => {
      console.log(res);
      const settingsData = GetOnboardingSettingsData();
      settingsData.then(res => {
        handleSettingsDataUpdate(res);
      });
    });
  };

  const handleSettingsDataUpdate = (res) => {
    // set generated pages list
    let generatedPagesList: GeneratedPage[] = [];
    res.invite_page_info.map(page => {
      generatedPagesList.push({
        name: page[0],
        total: page[1],
        used: page[2],
        expiryDate: new Date(page[3]),
        pageLink: '/onboarding/invites/' + page[4],
      })
    });
    const sortedList = sortInviteLinksListByExpiryDateDescending(generatedPagesList);
    setBoundaryBucketMin(res.boundary_bucket_min);
    setBoundaryBucketMax(res.boundary_bucket_max);
    setBoundaryBucketNumBins(res.boundary_bucket_num_bins);
    setGeneratedPages(sortedList);
    setRegistrationOpened(res.registrationOpen);
    setWaitlistEnabled(res.waitlistEnabled)
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleSendUploadedEmailInvites = async () => {
    if (!file) {
      return;
    }
    const req = UploadInvitesList(file);
    req.then(res => {
      setEmailInviteSnackbarOpen(true);
      // TODO: get emails that sent and failed from res and display them; 'send_emails': success_emails, 'fail_emails': fail_emails}
      console.log(res);
    })
  };

  const handleRegistrationActiveToggle = (checked: boolean) => {
    const req = UpdateResigtrationAccess(checked);
    req.then(res => {
      if (res.success) {
        setRegistrationOpened(checked);
        setRegistrationAccessSnackbarOpen(true);
      }
    })
  }

  const handleWaitlistEnabledToggle = (checked: boolean) => {
    const req = UpdateWaitlistEnabled(checked);
    req.then(res => {
      if (res.success) {
        setWaitlistEnabled(checked);
        setRegistrationAccessSnackbarOpen(true);
      }
    })
  }

  useEffect(() => {
    const settingsData = GetOnboardingSettingsData();
    settingsData.then(res => {
      handleSettingsDataUpdate(res);
    });
    showGeneratedLinks();
  }, []);

  return (
    <>
      {/* is there a humane way to do this? */}
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'left' }} open={iterativeTrainingSnackbarOpen} autoHideDuration={3000} onClose={handleIterativeTrainingSnackbarClose} sx={{ position: 'fixed' }}>
          <Alert onClose={handleIterativeTrainingSnackbarClose} severity="info" icon={false} sx={{ width: '100%' }}>
            Iterative Training Parameters Updated!
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'left' }} open={emailInviteSnackbarOpen} autoHideDuration={3000} onClose={handleEmailInviteSnackbarClose}>
          <Alert onClose={handleEmailInviteSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
            Email sent!
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'left' }} open={registrationAccessSnackbarOpen} autoHideDuration={3000} onClose={handleRegistrationAccessSnackbarClose}>
          <Alert onClose={handleRegistrationAccessSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
            Registration access updated.
          </Alert>
        </Snackbar>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={iterativeTrainingSnackbarOpen} autoHideDuration={3000} onClose={handleIterativeTrainingSnackbarClose} sx={{ position: 'fixed' }}>
          <Alert onClose={handleIterativeTrainingSnackbarClose} severity="info" icon={false} sx={{ width: '100%' }}>
            Iterative Training Parameters Updated!
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={emailInviteSnackbarOpen} autoHideDuration={3000} onClose={handleEmailInviteSnackbarClose}>
          <Alert onClose={handleEmailInviteSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
            Email sent!
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={registrationAccessSnackbarOpen} autoHideDuration={3000} onClose={handleRegistrationAccessSnackbarClose}>
          <Alert onClose={handleRegistrationAccessSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
            Registration info updated.
          </Alert>
        </Snackbar>
      </Box>

      <BoxSettingsContent>
        <Box
          sx={{
            '& > :not(style)': { m: 1 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h6'>
            Allow registration for everyone:
          </Typography>
          <FormControlLabel
            value="start"
            sx={{ flexDirection: 'row' }}
            control={
              <Switch
                checked={registrationOpened}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleRegistrationActiveToggle(event.target.checked);
                }}
                color="secondary"
              />
            }
            label={registrationOpened ? "Registration Opened" : "Registration Closed"}
            labelPlacement="start"
          />
          <Typography variant='h6'>
            Enable waitlist:
          </Typography>
          <FormControlLabel
            value="start"
            sx={{ flexDirection: 'row' }}
            control={
              <Switch
                checked={waitlistEnabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleWaitlistEnabledToggle(event.target.checked);
                }}
                color="secondary"
              />
            }
            label={waitlistEnabled ? "Waitlist Enabled" : "Waitlist Disabled"}
            labelPlacement="start"
          />
          <Typography variant='h6'>
            Generate onboarding links:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '40px' }}>
            <FormControl variant="standard" sx={{ width: '100px' }}>
              <InputLabel color="secondary" htmlFor="generateLinkNumber">Quantity:</InputLabel>
              <Input
                id="generateLinkNumber"
                defaultValue={5}
                value={generateNumberOfLinks}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGenerateLinkNumber(Number(event.target.value));
                }}
                color="secondary"
                type="number"
              />
            </FormControl>
            <Button color='secondary' variant='outlined' sx={{ ml: '3px', mr: '3px', mt: '10px' }} onClick={downloadAsTxt}>Download as .txt</Button>
            <Button color='secondary' variant='outlined' sx={{ ml: '3px', mr: '3px', mt: '10px' }} onClick={showGeneratedLinks}>Show Links</Button>
          </Box>
          <Box>
            {generatedLinks.map((link) =>
            (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography>{link}</Typography>
                <IconButton sx={{ height: '20px', width: '20px', ml: '5px' }} onClick={() => { navigator.clipboard.writeText(link) }}>
                  <ContentCopyIcon sx={{ height: '20px', width: '20px' }} />
                </IconButton>
              </Box>
            ))}
          </Box>

          <Divider />
          <Typography variant='h6'>
            Invite user by email:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '40px' }}>
            <FormControl variant="standard" sx={{ width: '300px' }}>
              <InputLabel color="secondary" htmlFor="inviteEmail">Email:</InputLabel>
              <Input
                id="inviteEmail"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInviteEmail(event.target.value);
                }}
                color="secondary"
              />
            </FormControl>
            <Button color='secondary' variant='outlined' sx={{ ml: '3px', mr: '3px', mt: '10px' }} onClick={sendInviteEmail}>Invite</Button>
          </Box>

          <Divider />
          <Typography variant='h6'>
            Upload a file with multiple emails:
          </Typography>
          <Typography variant='body2'>
            Your file should contain emails separated by either blanks or newlines.
            The file ending doesn't matter.
          </Typography>
          <div>{file && `${file.name} - ${file.type}`}</div>
          <input
            ref={uploadInputRef}
            style={{ display: 'none' }}
            type="file"
            onChange={handleFileChange}
          />
          <Button component="span" variant='outlined' color='secondary' sx={{ width: '200px' }}
            onClick={() => uploadInputRef.current && uploadInputRef.current.click()}>
            Upload
          </Button>
          <Button
            color='secondary'
            variant='outlined'
            sx={{ ml: '3px', mr: '3px', mt: '10px', width: '200px' }}
            onClick={handleSendUploadedEmailInvites}
            disabled={file === undefined}
          >
            Send
          </Button>

          <Divider />
          <Typography variant='h6'>
            Generate onboarding page:
          </Typography>
          <FormControl variant="standard" sx={{ width: '300px' }}>
            <InputLabel color="secondary" htmlFor="linksOnPageNumber">Links of page:</InputLabel>
            <Input
              id="linksOnPageNumber"
              defaultValue={10}
              value={numberOfLinksOnPage}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLinksOnPageNumber(Number(event.target.value));
              }}
              color="secondary"
              type="number"
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: '300px' }}>
            <InputLabel color="secondary" htmlFor="onboardingName">Name:</InputLabel>
            <Input
              id="onboardingName"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOnboardingName(event.target.value);
              }}
              color="secondary"
            />
          </FormControl>
          <Button color='secondary' variant='outlined' sx={{ ml: '3px', mr: '3px', width: '200px' }} onClick={generatePageLink}>Generate Invitation</Button>

          <Divider />
          <Typography variant='h6'>
            Generated pages:
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Used</TableCell>
                <TableCell>Expiry Date</TableCell>
                <TableCell>Page Link</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generatedPages.map((page, idx) => (
                <TableRow
                  key={page.pageLink}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{page.name}</TableCell>
                  <TableCell>{page.total}</TableCell>
                  <TableCell>{page.used}</TableCell>
                  <TableCell>{page.expiryDate.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}</TableCell>
                  <TableCell><Link href={page.pageLink} target='_blank' color='secondary'>Link</Link></TableCell>
                  <TableCell>
                    <Button color='secondary' variant='outlined' onClick={() => { deleteGeneratedPage(page.pageLink.split('/').at(-1)) }}>
                      delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Divider />
          <Typography variant='h6'>
            Iterative Training
          </Typography>
          <FormControl variant="standard" sx={{ width: '300px' }}>
            <InputLabel color="secondary" htmlFor="boundaryBucketMin">Boundary Bucket Min:</InputLabel>
            <Input
              id="boundaryBucketMin"
              defaultValue={-0.5}
              value={boundaryBucketMin}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBoundaryBucketMin(Number(event.target.value));
              }}
              color="secondary"
              type="number"
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: '300px' }}>
            <InputLabel color="secondary" htmlFor="boundaryBucketMax">Boundary Bucket Max:</InputLabel>
            <Input
              id="boundaryBucketMax"
              defaultValue={0.5}
              value={boundaryBucketMax}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBoundaryBucketMax(Number(event.target.value));
              }}
              color="secondary"
              type="number"
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: '300px' }}>
            <InputLabel color="secondary" htmlFor="boundaryBucketBinSize">Boundary Bucket Number of Bins:</InputLabel>
            <Input
              id="boundaryBucketBinSize"
              defaultValue={0.1}
              value={boundaryBucketNumBins}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBoundaryBucketNumBins(Number(event.target.value));
              }}
              color="secondary"
              type="number"
            />
          </FormControl>
          <Button color='secondary' variant='outlined' sx={{ ml: '3px', mr: '3px', width: '300px' }} onClick={updateIterativeTraining}>Update Iterative Training Params</Button>
        </Box>
      </BoxSettingsContent>
    </>
  )
}
